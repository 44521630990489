
import { Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import ETable from "@/components/ETable.vue";
import loading from "@/mixins/loading";
import { cloneDeep } from "lodash"
import EditDialog from "./components/editDialog.vue";
import { selectionInfoPage, deleteRecommend } from "@/api/recommendation";


const baseForm = {
    title: "",
    path: "",
    sort: 0,
    status: true,
    imgUrl: [],
}

@Component({
    components: { ETable, EditDialog }
})
export default class selectRecommendation extends Mixins(loading, tablePagination) {
    EditDialogVisible: boolean = false;
    dialogTitle = "";
    queryForm = {
        title: ""
    };
    itemTotal = 0;
    tableData = [];

    columns = [
        { label: "ID", prop: "id" },
        { label: "标题", prop: "title" },
        { label: "图片", prop: "imgUrl", width: "220px" },
        { label: "跳转路径", prop: "path" },
        { label: "排序", prop: "sort" },
        { label: "发布状态", prop: "status" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action" }
    ]
    
    formData = {
        title: "",
        path: "",
        sort: 0,
        status: true,
        imgUrl: [],
    }

    mounted() {
        this.getData();
    }

    searchData() {
        this.resetCurrent();
        this.getData();
    }

    getData() {
        const params = {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            ...this.queryForm,
        }
        this.showLoading();
        selectionInfoPage(params).then((res) => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        })
    }

    handleDelete(row) {
        this.$confirm(`删除后无法恢复，确认删除？`, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
        }).then(() => {
            deleteRecommend({ id: row.id }).then((res) => {
                this.$message.success("删除成功");
                this.getData();
            })
        })
    }

    handleEdit(row) {
        this.dialogTitle = row.id ? "编辑" : "新增";
        if (row.id) {
            const { status, imgUrl } = row;
            Object.assign(this.formData, { ...row, status: Boolean(status), imgUrl: [{url: imgUrl}] });
        }
        else {
            this.formData = cloneDeep(baseForm);
        }
        this.EditDialogVisible = true;
    }

    editConfirm() {
        this.getData();
        this.EditDialogVisible = false;
    }
}

