
import { Component, Prop, Vue, Watch, Ref, Mixins, PropSync } from "vue-property-decorator";
import { Form } from "element-ui/types";
import UploadImg from "@/components/uploadImg.vue";
import TablePagination from "@/mixins/tablePagination";
import { editRecommend } from "@/api/recommendation";

@Component({
    components: { UploadImg }
})
export default class EditDialog extends Mixins(Vue, TablePagination ) {
    @Ref("validateForm") formRef: Form;
    @PropSync("formData") private formDataSync!: any;
    @Prop([String]) private dialogTitle!: string;
    @Prop([Boolean]) private dialogVisible!: boolean;

    formRules = {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        imgUrl: [{ required: true, message: "请上传图片", trigger: "blur" }]
    };

    saveLoading = false;

    mounted() {
    }

    dialogClose() {
        this.$emit('closeEditDialog');
        this.formRef?.resetFields();
        this.saveLoading = false;
    }

    saveSymptomsInfo() {
        this.formRef?.validate((valid) => {
            if (valid) {
                const _status = Number(this.formDataSync.status);
                const _imgUrl = this.formDataSync.imgUrl[0].url;
                this.saveLoading = true;
                editRecommend({ ...this.formDataSync, status: _status, imgUrl: _imgUrl }).then((res) => {
                    this.$message.success("保存成功");
                    this.$emit('editDialogConfirm');
                    this.dialogClose();
                }).finally(() => {
                    this.saveLoading = false;
                })
            } else {
                return false;
            }
        })
    }
}
