import { service } from '@/ajax/request';

export const editRecommend = (params: any) => (
    service({
        url: '/selectionRecommend/editRecommend',
        method: 'POST',
        params,
    })
)

export const selectionInfoPage = (params: any) => (
    service({
        url: '/selectionRecommend/selectionInfoPage',
        method: 'POST',
        params,
    })
)

export const deleteRecommend = (params: any) => (
    service({
        url: '/selectionRecommend/deleteRecommend',
        method: 'POST',
        params,
    })
)
